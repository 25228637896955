import React, { useEffect, useState } from 'react';
import {Link, useParams } from 'react-router-dom';

import profile from './../../../images/profile/pic2.jpg';
import Swal from 'sweetalert2';
import { UseAPI } from '../../../services/UseAPI';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import FormElementsVertical from '../Forms/FormElementsVerical';


const TryoutDetail = ()=>{
	const { tryout_id } = useParams();
	const [data, setData] = useState({})
	const history = useHistory();
	// let navigate = useNavigate();
	const mulai = async () => {
		//console.log("id",tryout_id);
		await UseAPI('pass_code/'+tryout_id,{},'GET').then((resp)=>{
			if (resp.status == 'ok') {
				Swal.fire({
					title: 'Confirmation Code',
					html: resp.pesan,
					input: 'text',
					inputAttributes: {
					  autocapitalize: 'off'
					},
					showCancelButton: true,
					confirmButtonText: 'Confirm',
					showLoaderOnConfirm: true,
					preConfirm: (confirmPassCode) => {
						return UseAPI('pass_code/confirm/'+tryout_id+'/'+confirmPassCode,{},'GET').then((resp_pass_code)=>{
							//console.log(resp_pass_code);
							if (resp_pass_code.status == 'ok') {
								return resp_pass_code
							} else {
								throw new Error(resp_pass_code.pesan)
							}
						}).catch(error => {
							Swal.fire({
								icon:'error',
								title: `Error`,
								text: `${error}`
							})

						})
					},
					allowOutsideClick: () => !Swal.isLoading()
				}).then((result) => {
					if (result.isConfirmed) {
						//console.log(result);
						let timerInterval
						Swal.fire({
							icon:'success',
							title: `Confirmed`,
							html:'<b></b>',
							// text: `${result.value.pesan}`,
							showConfirmButton:false,
							timer:3000,
							timerProgressBar: true,
							didOpen: () => {
								Swal.showLoading()
								const b = Swal.getHtmlContainer().querySelector('b')
								timerInterval = setInterval(() => {
								  b.textContent = parseInt((Swal.getTimerLeft()/1000)+1)
								}, 100)
							},
							  willClose: () => {
								clearInterval(timerInterval)
							}							
						}).then((result)=>{
							if (result.dismiss === Swal.DismissReason.timer) {
								history.push(`/ujian/${tryout_id}`)
								// navigate("/tryout/start")
							}
						})
					}
				})
			} else {
				Swal.fire({
					icon:'error',
					title: `Error`,
					text: `${resp.pesan}. `
				})
			}

	

		})		
		// fetch(`https://w`)	
		// .then(response => {
		//   if (!response.ok) {
		// 	throw new Error(response.statusText)
		//   }
		//   return response.json()
		// })		

	}

	useEffect(()=>{
		UseAPI(`participant_tryouts/user/${tryout_id}`,{},'GET').then((resp)=>{
			//console.log("resp",resp);
			if (resp) {
				setData(resp);
			}
		})
	},[])
	return(
		<>
			<div className="row">	
				<div className="col-xl-12">
					<div className="card">
						{data.length > 0 && (
						<>
							<div className="card-body">
								<div className="row align-items-center">
									
									<div className="col-xl-3 mb-3">
										<p className="mb-2 fs-16 font-w600">Type</p>
										<h2 className="mb-0 fs-32 font-w800">{data[0] && data[0].type_name}</h2>
									</div>
									<div className="col-xl-9 d-flex flex-wrap justify-content-between align-items-center">
										<div className="d-flex me-3 mb-3 ms-2 align-items-start payment">
											{/* <i className="fas fa-phone-alt me-4 mt-2 scale5"></i> */}
											<div>
												<p className="mb-2 fs-16 font-w600">Test Name</p>
												<h4 className="mb-0 fs-18 font-w700">{data[0] && data[0].tryout_name}</h4>
											</div>
										</div>
										<div className="d-flex me-3 mb-3 ms-2 align-items-start payment">
											{/* <i className="fas fa-envelope scale5 me-4 mt-2"></i> */}
											<div>
												<p className="mb-2 fs-16 font-w600">Status</p>
												<h4 className="mb-0 fs-18 font-w700">{data[0] && data[0].status === '1' ? 'Finished' : 'Unfinished'}</h4>
											</div>
										</div>
										<div className="d-flex mb-3">
											{data[0] && data[0].is_expired > 0 ? (
												<Link to={"#"} className="btn btn-primary"><FontAwesomeIcon icon={faLock} /> Not Available</Link>												
											):(
												<Link to={"#"} className="btn btn-primary" onClick={()=>mulai()}><i className="fas fa-check me-3"></i>Start</Link>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className="card-body py-3 transaction-details d-flex flex-wrap justify-content-between align-items-center">
								<div className="user-bx-2 me-3 mb-3">
									<img src={profile} className="rounded" alt="" />
									<div>
										<h3 className="fs-20 font-w700">{data[0] && data[0].first_name}</h3>
										<span className="font-w400">user</span>
									</div>
								</div>
								{/* <div className="me-3 mb-3">
									<p className="mb-2">Jenis Test/TO</p>
									<h4 className="mb-0">{data[0] && data[0].type_name}</h4>
								</div> */}
								<div className="me-3 mb-3">
									<p className="mb-2">Start</p>
									<h4 className="mb-0">{data[0] && data[0].test_start.split(' ')[0]}<br />{data[0] && data[0].test_start.split(' ')[1]}</h4>
								</div>
								<div className="me-3 mb-3">
									<p className="mb-2">Finish</p>
									<h4 className="mb-0">{data[0] && data[0].test_finish.split(' ')[0]}<br />{data[0] && data[0].test_finish.split(' ')[1]}</h4>
								</div>
								<div className="me-3 mb-3">
									<p className="mb-2">Durations</p>
									<h4 className="mb-0 fs-18 font-w700">{data[0] && data[0].exam_duration_minutes} menit</h4>
								</div>

							</div>
						</>
						)}

						{data.length == 0 && (
							<div className="d-xl-flex d-block align-items-start description-bx p-3">
								<p>You are not registered for this test</p>
							</div>


						)}
					</div>
				</div>
				<div className="col-xl-12">
					<div className="card">	
						<div className="card-body">
							<div className="d-xl-flex d-block align-items-start description-bx">
								<div>
									<h4 className="fs-20 font-w700">Description</h4>
									<span className="fs-12">
										<FormElementsVertical 
											value={data[0]?.description} 
											type="textarea"
											toolBar="false"
											readOnly="true" 
										/>
									</span>
									<p className="description mt-4"></p>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</>
	)
}
export default TryoutDetail;