import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faSailboat, faSquare, faNewspaper } from '@fortawesome/free-solid-svg-icons'

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
  return (
    <div className="nav-header" style={{height:'5rem'}}>
      <Link to="/dashboard" className="brand-logo">
        {background.value === "dark" || navigationHader !== "color_1" ? (
			<Fragment>
        <img src="../../images/logo-1.png"></img>
			</Fragment>
        ) : (
			<Fragment>
        <span className="fa-layers fa-fw fa-3x">
          <FontAwesomeIcon icon={faSquare} color="darkblue" />
          <FontAwesomeIcon icon={faNewspaper} inverse transform="shrink-6" />
        </span>        
				<div className="brand-title">
					<h2 className="">EXAMMU</h2>
					<span className="brand-sub-title">Dashboard</span>					
				</div>
			</Fragment>

        )}
      </Link>

      <div
        className="nav-control d-flex align-items-center"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
