 import React from "react";

const Footer = () => {
  var d = new Date();
  return (
    <div className="footer fixed-bottom" style={{zIndex:0, boxShadow: "0px -3px 1px 3px rgba(253, 171, 0, 0.5), 0px -3px 1px 3px rgba(253, 171, 0, 0.3)"}}>
      <div className="copyright p-1 m-1">
        <p>
          Copyright © Designed &amp; Developed by{" "}
          <a href={'/#'} target="_blank" rel="noreferrer">
            SIMUT - ICT
          </a>{" "}
          {d.getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
