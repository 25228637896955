import React, { Fragment } from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";

const JobieNav = ({ title, onClick: ClickToAddEvent }) => {
  return (
    <Fragment>
      <NavHader />
      <Header
        title={title}
        onClick={() => ClickToAddEvent()}
      />
      <SideBar />
    </Fragment>
  );
};

export default JobieNav;
